import { DEFAULT_LANGUAGE as LANG } from './feature-flags';
/**
 * ************************************************
 * Time & Language Property
 * ************************************************
 */
import dayjs from 'dayjs';
import 'dayjs/locale/id';
import 'dayjs/locale/en';

export const PASSWORD_LINK_GENERATOR = 'semogasukses';

/**
 * ************************************************
 * Flag to to set Bride to BOY first
 * ************************************************
 */
export const IS_BOY_FIRST = true;

/**
 * ************************************************
 * Bride & Groom Info
 * ************************************************
 */
export const GIRL_NAME = 'New Doctor';
export const GIRL_NAME_SHORT = 'New Doctor';
export const GIRL_FATHER_NAME = `New Doctor`;
export const GIRL_MOTHER_NAME = `New Doctor`;
export const GIRL_PARENT_NAME =
  LANG === 'en'
    ? `The Daughter of <br/> Mr. ${GIRL_FATHER_NAME} & Mrs. ${GIRL_MOTHER_NAME}`
    : `Putri dari <br/> Bapak ${GIRL_FATHER_NAME} & Ibu ${GIRL_MOTHER_NAME}`;
// -> boy section
export const BOY_NAME = 'New Doctor';
export const BOY_NAME_SHORT = 'New Doctor';
export const BOY_FATHER_NAME = `New Doctor`;
export const BOY_MOTHER_NAME = `New Doctor`;
export const BOY_PARENT_NAME =
  LANG === 'en'
    ? `The Son of <br/> Mr. ${BOY_FATHER_NAME} & Mrs. ${BOY_MOTHER_NAME}`
    : `Putra dari <br/> Bapak ${BOY_FATHER_NAME} & Ibu ${BOY_MOTHER_NAME}`;
// -> bride section
export const BRIDE_HASHTAG = `Bravely and Faithfully`;
export const THE_BRIDE = IS_BOY_FIRST
  ? `Fortiter et Fideliter`
  : `Fortiter et Fideliter`;

/**
 * ************************************************
 * Instagram Profile Account
 * @important please put instagram id without `@`
 * ************************************************
 */
export const IG_BOY = 'sumpahdokter.atmajaya';
export const IG_GIRL = 'sumpahdokter.atmajaya';
export const IG_FILTER = `https://www.instagram.com/ar/1055603985773081/`;

/**
 * ************************************************
 * SEO Requirement
 * @important - Don't forget to update SEO IMAGE
 * ************************************************
 */
export const SEO_IMAGE = `https://ik.imagekit.io/bxwgcojbe/sumpah-dokter-atmajaya-batch-1-2024/seo-new.jpg?updatedAt=1705391543773`;
export const SEO_URL = 'https://invitato.net/';
export const SEO_TITLE = `Hippocratic Oath Atma Jaya by Invitato`;
export const SEO_DESCRIPTION =
  LANG === 'en'
    ? `With honor we are inviting you to our Hippocratic Oath Batch I 2024 School of Medicine and Health Sciences Atma Jaya Catholic University of Indonesia. Please click the Website Invitation link on this message for more information details.`
    : `Menjadi sebuah kebahagiaan bagi kami untuk mengumumkan awal dari babak baru kehidupan kami bersama. Silakan klik tautan situs Undangan Website di bawah untuk informasi lebih lanjut:`;

/**
 * ************************************************
 * Time requirement for Counting Down, and
 * Remind me generator
 * @important - please convert time to Epoch time by
 * using this link https://www.epochconverter.com/
 * ************************************************
 */
export const EPOCH_START_EVENT = 1706664600;
export const EPOCH_RESEPSI_START = 1706664600;
export const EPOCH_END_EVENT = 1706675400;

/**
 * ************************************************
 * DATE REQUIREMENT
 * @important - Format date YYYY-MM-DD
 * ************************************************
 */
export const DATE_AKAD = new Date('2024-01-31');
export const DATE_RESEPSI = DATE_AKAD;

export const WEDDING_AKAD_TIME = '08.30 WIB';
export const WEDDING_AKAD_LOC_NAME = 'Auditorium Yustinus Lantai 15, <br />Universitas Katolik Indonesia Atma Jaya (Kampus Semanggi)';
export const WEDDING_AKAD_LOC_ROAD = `Jl. Garnisun 1 No. 1 5, RT 05 RW 04, Karet Semanggi, Kec. Setiabudi, Kota Jakarta Selatan, DKI Jakarta 12930`;
export const WEDDING_AKAD_DRESSCODE = ``;
export const WEDDING_AKAD_FULLDATE = dayjs(DATE_AKAD).locale(LANG).format('dddd, DD MMMM YYYY');

export const WEDDING_RESEPSI_TIME = '08.30 WIB';
export const WEDDING_RESEPSI_LOC_NAME = `Auditorium Yustinus Lantai 15, <br />Universitas Katolik Indonesia Atma Jaya (Kampus Semanggi)`;
export const WEDDING_RESEPSI_LOC_ROAD = `Jl. Garnisun 1 No. 1 5, RT 05 RW 04, Karet Semanggi, Kec. Setiabudi, Kota Jakarta Selatan, DKI Jakarta 12930`;
export const WEDDING_RESEPSI_DRESSCODE = ``;
export const WEDDING_RESEPSI_FULLDATE = dayjs(DATE_RESEPSI)
  .locale(LANG)
  .format('dddd, DD MMMM YYYY');
export const WEDDING_RESEPSI_DATE = WEDDING_AKAD_FULLDATE;
// export const WEDDING_RESEPSI_DATE = dayjs(DATE_RESEPSI)
//   .locale(DEFAULT_LANGUAGE)
//   .format('DD • MM • YYYY');

/**
 * ********************************************************
 * Link Generator V2
 * @important - this info will be applied at link generator
 * ********************************************************
 */
export const HOSTNAME = 'https://fortiteretfideliter.com/';
export const BROADCAST_WEDDING_LOCATION = `Auditorium Yustinus Lantai 15, Universitas Katolik Indonesia Atma Jaya (Kampus Semanggi), Jakarta Selatan`;
export const BROADCAST_WEDDING_DAY = {
  id: dayjs(DATE_RESEPSI).locale('id').format('dddd, DD MMMM YYYY'),
  en: dayjs(DATE_RESEPSI).locale('en').format('dddd, DD MMMM YYYY'),
};

/**
 * ************************************************
 * Maps Location
 * ************************************************
 */
export const GOOGLE_MAPS_LINK = `https://maps.app.goo.gl/9tTVqFcvM7shqbQ1A`;
export const GOOGLE_MAPS_ADDRESS = `Universitas Katolik Atmajaya`;

/**
 * ************************************************
 * Backsound Copyright
 * ************************************************
 */
export const SOUND_BY = `벨라 앤 루카스 BELLA & LUCAS - 너, 나, 우리 (You and I)`;
export const SOUND_URL = 'https://youtu.be/7W2mV8dKcY0?si=Ft97QliJ0jWECYc7';

/**
 * ************************************************
 *  Youtube Live requirement
 * ************************************************
 */
export const YOUTUBE_LINK = 'https://www.youtube.com/live/LK_KRG6B4yo?si=aMLE1tQ22q4Dmgxb';
export const YOUTUBE_EMBED = 'https://www.youtube.com/embed/LK_KRG6B4yo?si=aMLE1tQ22q4Dmgxb';
export const YOUTUBE_PREWEDDING_EMBED = `https://www.youtube.com/embed/WEC5RezD5jU`;
export const YOUTUBE_PREWEDDING_LINK = `https://youtu.be/WEC5RezD5jU`;

/**
 * ************************************************
 *  Invitato Link Requirement
 * ************************************************
 */
export const URL_INVITATO = 'https://invitato.net';
export const URL_IG_INVITATO = 'https://www.instagram.com/dindadipoyono/';
export const URL_WA_INVITATO = 'https://wa.me/+628112778189?text=Hi%20Kak,%20aku%20mau%20pesan%20undangan%20online%20nih!';